import { createMuiTheme } from "@material-ui/core"
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles"
import React from "react"

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: "#4989be",
        light: "#7db9f1",
        dark: "#005c8e",
      },
      secondary: {
        main: "#ff8a40",
        light: "#ffbb6e",
        dark: "#c65b0e",
      },
      text: {
        secondary: "#4a494a",
      },
    },
    typography: {
      button: {
        fontSize: 16,
      },
      body1: {
        fontSize: 18,
      },
      body2: {
        fontSize: 16,
      },
    },
    mixins: {
      toolbar: {
        "@media (min-width:0px) and (orientation: landscape)": {
          minHeight: 48,
        },
        "@media (min-width:960px)": {
          minHeight: 114,
          margin: "0",
        },
        "@media (min-width:1280px)": {
          minHeight: 114,
          margin: "0 64px",
        },
        minHeight: 64,
      },
    },
  })
)

export default ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
)
